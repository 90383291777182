import axios from 'axios'
const API_URL = 'https://api.xchangeflight.com/api'

const headers = {
    'api_key': '123',
};
export async function EmailVerification(token) {
    try {
        const response = await axios.get(API_URL+`/verify-email/${token}`,  {
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        });
        // Handle success response
        return response.data
    } catch (error) {
        return error.response.data
    }
}
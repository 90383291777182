import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {EmailVerification} from "./core/_request";



export default function App() {
  const location = useLocation();
  const [token, setToken] = useState('');
  const [status, setStatus] = useState('failed');

  useEffect(() => {
    // Extract the token from the URL path
    const pathTokens = location.pathname.split('/');
    const tokenFromPath = pathTokens[pathTokens.length - 1];
    setToken(tokenFromPath);
  }, [location]);
  const verifyEmail = async () => {
    if (token) {
      try {
        const response = await EmailVerification(token);
        setStatus(response.message)
      } catch (error) {
        setStatus(error.message)
        console.error('Email verification failed:', error);
      }
    }
  };

  useEffect(() => {
    verifyEmail();
  }, [token]);

  return ( <div>
        <div className='text-center mb-10 ' style={{marginTop:200,}}>
        <img src="https://xchangeflight.com/logo.png" alt="Logo xchangeFlight" className="mb-3 mx-auto w-40" />
        </div>
        {status == 'success' && <div className='text-center mb-10'>
          <h1 className='text-dark fw-bolder mb-3'>Your email has been verified successfully</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
           Thank you your account has been verified successfully.
          </div>
        </div>}
        {status == 'failed' && <div className='text-center mb-10 ' >
          <h1 className='text-dark fw-bolder mb-3 mt-10'>Your email verification has failed</h1>
          
        </div>}
      </div>

  );
}
